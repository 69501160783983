import React, { useRef, useState } from "react";
import { FileUploaderTypeEnum, MIME_TYPES } from "./FileUploader.constants";
import styles from "./FileUploader.module.scss";
import { TFileUploaderProps } from "./FileUploader.types";
import { FontWeightEnum, Text, TextVariantsEnum } from "../Text";
import { SvgIcon } from "../SvgIcon";

const MAX_FILE_SIZE_MB = process.env.REACT_APP_ENVIRONMENT === 'dev' ? 30 : 50;
const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024;
const MAX_VIDEO_DURATION = 5 * 60;

export const FileUploader: React.FC<TFileUploaderProps> = ({
                                                               handleFileUpload,
                                                               file
                                                           }) => {
    const invisibleInputRef = useRef<any>(null);
    const [error, setError] = useState<string>("");
    const [videoDuration, setVideoDuration] = useState<number | null>(null);

    const validateFileSize = (file: File): boolean => {
        return file.size <= MAX_FILE_SIZE_BYTES;
    };

    const handleFileChange = (selectedFile: File | null | undefined) => {
        setError("");
        setVideoDuration(null);

        if (!selectedFile) {
            handleFileUpload && handleFileUpload({ file: null, thumbnail: null, duration: null });
            return;
        }

        if (!validateFileSize(selectedFile)) {
            setError(`File size must be less than ${MAX_FILE_SIZE_MB}MB`);
            return;
        }

        const video = document.createElement("video");
        const fileURL = URL.createObjectURL(selectedFile);

        video.src = fileURL;
        video.addEventListener("loadedmetadata", () => {
            const duration = video.duration;

            if (duration > MAX_VIDEO_DURATION) {
                setError(`Video duration must be less than 5 minutes`);
                URL.revokeObjectURL(fileURL);
                return;
            }

            setVideoDuration(duration);

            const canvas = document.createElement("canvas");
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;

            video.currentTime = 1;

            async function dataURLtoFile(dataurl: any, filename: any) {
                const response = await fetch(dataurl);
                const blob = await response.blob();
                return new File([blob], filename, { type: blob.type });
            }

            video.addEventListener("seeked", async () => {
                const ctx = canvas.getContext("2d");
                if (ctx) {
                    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
                    const thumbnail = canvas.toDataURL("image/jpeg");
                    const thumbnailFile = await dataURLtoFile(thumbnail, "thumbnail.jpg");
                    handleFileUpload &&
                    handleFileUpload({
                        file: selectedFile,
                        thumbnail: thumbnailFile,
                        duration: Math.ceil(duration),
                    });

                    URL.revokeObjectURL(fileURL);
                }
            });
        });
    };


    const handleAreaClick = () => {
        if (!file) {
            invisibleInputRef?.current?.click();
        }
    };

    return (
        <div
            className={styles.uploaderWrapper}
            onClick={handleAreaClick}
        >
            <input
                className={styles.invisibleInput}
                type="file"
                accept={MIME_TYPES[FileUploaderTypeEnum.VIDEO].join(",")}
                name="invisible_input"
                id="invisible_input"
                onChange={(e) => handleFileChange(e.target.files?.[0])}
                ref={invisibleInputRef}
            />
            <div className={styles.uploadIcon}>
                <SvgIcon src="icons/upload.svg" />
            </div>
            {file && file.name ? (
                <div className={styles.deleteText} onClick={(e) => e.stopPropagation()}>
                    <Text
                        className={styles.removeFileWrapper}
                        variant={TextVariantsEnum.Text_sm}
                        fontWeight={FontWeightEnum.Bold}
                        color="primary-700"
                    >
                        {file.name}
                    </Text>
                    <SvgIcon
                        src={'icons/delete.svg'}
                        size={18}
                        color="error"
                        onClick={() => handleFileChange(null)}
                    />
                </div>
            ) : (
                <div className={styles.uploadText}>
                    <Text
                        variant={TextVariantsEnum.Text_sm}
                        fontWeight={FontWeightEnum.Bold}
                        color="primary-700"
                    >
                        Click here
                    </Text>
                    <Text
                        variant={TextVariantsEnum.Text_sm}
                        fontWeight={FontWeightEnum.Medium}
                        color="neutral-50"
                    >
                        to upload media files
                    </Text>
                </div>
            )}
            {error && (
                <Text
                    variant={TextVariantsEnum.Text_sm}
                    fontWeight={FontWeightEnum.Medium}
                    color="error"
                    className={styles.errorMessage}
                >
                    {error}
                </Text>
            )}
        </div>
    );
};
