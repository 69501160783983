import { useState, useRef } from 'react';
import Map, {
    NavigationControl,
    Marker,
    Popup,
    MapRef
} from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import styles from './Map.module.scss';

interface LocationData {
    screenName: string;
    bookable: string;
    requiredResolution: string;
    screens: number;
    mediaType: string;
    type: string;
    duration: string;
    acceptedDurations: string;
    minimumPlays: number;
}

interface ViewState {
    latitude: number;
    longitude: number;
    zoom: number;
}

interface MapComponentProps {
    className?: string;
}

const dummyData: LocationData = {
    screenName: "Bengaluru Central Screen",
    bookable: "hourly",
    requiredResolution: "1920x1080",
    screens: 1,
    mediaType: "full motion video / static images",
    type: "outdoor",
    duration: "10s",
    acceptedDurations: "10s, 15s",
    minimumPlays: 300
};

const BENGALURU_LATITUDE = 12.9716;
const BENGALURU_LONGITUDE = 77.5946;
const INITIAL_ZOOM = 11;

const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN || process.env.VITE_MAPBOX_TOKEN;

export const MapComponent: React.FC<MapComponentProps> = ({ className }) => {
    const [viewState, setViewState] = useState<ViewState>({
        latitude: BENGALURU_LATITUDE,
        longitude: BENGALURU_LONGITUDE,
        zoom: INITIAL_ZOOM
    });
    const [showPopup, setShowPopup] = useState(false);
    const mapRef = useRef<MapRef>(null);

    const handleShowPopup = () => {
        setShowPopup(true);
        if (mapRef.current) {
            setViewState({
                ...viewState,
                latitude: BENGALURU_LATITUDE - 0.02,
                zoom: 11.5
            });
        }
    };

    return (
        <div className={styles.container}>
            <div className={`${styles.mapWrapper} ${showPopup ? styles.mapWithPopup : ''} ${className || ''}`}>
                <Map
                    ref={mapRef}
                    {...viewState}
                    onMove={evt => setViewState(evt.viewState)}
                    mapStyle="mapbox://styles/mapbox/streets-v12"
                    mapboxAccessToken={MAPBOX_TOKEN}
                    // style={{ width: '100%', height: '100%' }}
                    reuseMaps
                    dragRotate={false}
                >
                    <Marker
                        latitude={BENGALURU_LATITUDE}
                        longitude={BENGALURU_LONGITUDE}
                        anchor="bottom"
                        onClick={e => {
                            e.originalEvent.stopPropagation();
                            handleShowPopup();
                        }}
                    >
                        <div className={styles.markerPin}>
                            <svg viewBox="0 0 24 24">
                                <path d="M12 0C7.802 0 4 3.403 4 7.602C4 11.8 7.469 16.812 12 24C16.531 16.812 20 11.8 20 7.602C20 3.403 16.199 0 12 0ZM12 11C10.343 11 9 9.657 9 8C9 6.343 10.343 5 12 5C13.657 5 15 6.343 15 8C15 9.657 13.657 11 12 11Z"/>
                            </svg>
                        </div>
                    </Marker>

                    {showPopup && (
                        <Popup
                            latitude={BENGALURU_LATITUDE}
                            longitude={BENGALURU_LONGITUDE}
                            anchor="top"
                            offset={15}
                            onClose={() => setShowPopup(false)}
                            closeButton={true}
                            closeOnClick={false}
                            className={styles.customPopup}
                        >
                            <div className={styles.popupContent}>
                                <img
                                    src="/images/login.svg"
                                    alt="Location Preview"
                                    className={styles.previewImage}
                                />

                                <div className={styles.infoSection}>
                                    <h3 className={styles.title}>{dummyData.screenName}</h3>

                                    <div className={styles.infoGrid}>
                                        <div className={styles.infoItem}>
                                            <span className={styles.label}>Bookable</span>
                                            <span className={styles.value}>{dummyData.bookable}</span>
                                        </div>

                                        <div className={styles.infoItem}>
                                            <span className={styles.label}>Required resolution</span>
                                            <span className={styles.value}>{dummyData.requiredResolution}</span>
                                        </div>

                                        <div className={styles.infoItem}>
                                            <span className={styles.label}>Screens</span>
                                            <span className={styles.value}>{dummyData.screens}</span>
                                        </div>

                                        <div className={styles.infoItem}>
                                            <span className={styles.label}>Media Type</span>
                                            <span className={styles.value}>{dummyData.mediaType}</span>
                                        </div>

                                        <div className={styles.infoItem}>
                                            <span className={styles.label}>Type</span>
                                            <span className={styles.value}>{dummyData.type}</span>
                                        </div>

                                        <div className={styles.infoItem}>
                                            <span className={styles.label}>Duration</span>
                                            <span className={styles.value}>{dummyData.duration}</span>
                                        </div>

                                        <div className={styles.infoItem}>
                                            <span className={styles.label}>Accepted Durations</span>
                                            <span className={styles.value}>{dummyData.acceptedDurations}</span>
                                        </div>

                                        <div className={styles.infoItem}>
                                            <span className={styles.label}>Minimum plays</span>
                                            <span className={styles.value}>{dummyData.minimumPlays}</span>
                                        </div>
                                    </div>

                                    <div className={styles.actions}>
                                        <button className={styles.basicInfoButton}>
                                            See basic info
                                        </button>
                                        <button className={styles.locationButton}>
                                            View location page
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Popup>
                    )}

                    <NavigationControl
                        position="bottom-right"
                        showCompass={false}
                    />
                </Map>
            </div>
        </div>
    );
};

export default MapComponent;