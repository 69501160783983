import {
  FontWeightEnum,
  Text,
  TextVariantsEnum,
} from "../../../components/Text";
import { TUploadMediaProps } from "./UploadMedia.types";
import styles from "./UploadMedia.module.scss";
import { FileUploader } from "../../../components/FileUploader/FileUploader.component";
import Button from "../../../components/Button/Button";
import {
  ButtonTextSizeEnum,
  ButtonVariantEnum,
} from "../../../components/Button/button.types";
import { Input } from "../../../components/Input";

export const UploadMedia: React.FC<TUploadMediaProps> = ({
  onConfirm,
  title,
  description,
  onCancel,
  loading,
  modelStep,
  handleMediaDetails,
  mediaDataUploaded
}) => {

  const handleDisable = () => {
    if(modelStep === 1) return !mediaDataUploaded.file
    if(modelStep === 2) return !mediaDataUploaded.mediaName || !mediaDataUploaded.tags
    return false
  }

  console.log('Media file => ', mediaDataUploaded);

  return (
    <div className={styles.uploadWrapper}>
      <Text
        variant={TextVariantsEnum.Display_xs}
        fontWeight={FontWeightEnum.Bold}
        className={styles.header}
      >
      {title}
      </Text>
      <div className={styles.separator} />
      {modelStep == 1 ? <FileUploader file={mediaDataUploaded.file} handleFileUpload={(file) => {
        console.log(file);
        
        handleMediaDetails('file',file?.file)
        handleMediaDetails('thumbnail',file?.thumbnail)
        handleMediaDetails('length',file?.duration)

      }} /> : (
        <div className={styles.inputClass}>
        <Input
        type="text"
        placeholder="Media Name"
        value={mediaDataUploaded.mediaName}
        onChange={(e) => {
          handleMediaDetails('mediaName',e.target.value)
        }}
        width="100%"
        />
        <Input
        type="text"
        placeholder="Tags (after one tag use , to add another tag)"
        value={mediaDataUploaded.tags}
        onChange={(e) => {
          handleMediaDetails('tags',e.target.value)
        }}
        width="100%"
        />
        </div>
      )}
      <div className={styles.footer}>
        <Button
          text={ modelStep === 1 ? "Cancel" : "Back"}
          onClick={onCancel ? onCancel : () => {}}
          variant={ButtonVariantEnum.secondary}
          size={ButtonTextSizeEnum.sm}
          width={"120px"}
        />
        <Button
          text={modelStep === 1 ? "Next" : "Upload"}
          onClick={onConfirm}
          variant={ButtonVariantEnum.primary}
          size={ButtonTextSizeEnum.sm}
          width={"120px"}
          disabled={handleDisable()}
        />
      </div>
    </div>
  );
};
